import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation, Reservee} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {ApiError, isWixHttpError} from '../../../utils/errors'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation, navigateToCheckoutPage} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'
import {checkoutService} from '../../../services/checkoutService'
import {EXPERIMENTS} from '../../../utils/constants'
import {isFreeOrder} from '../../../utils/ecom'

export enum ReserveReservationApplicationErrorCode {
  HELD_EXPIRED = 'HELD_EXPIRED',
}

export interface ReserveReservationApiError
  extends ApiError<ReserveReservationApplicationErrorCode> {}

export const reserveReservation = (flowAPI: ControllerFlowAPI) => {
  return async (
    reservation: Reservation,
    reservee: Reservee,
    reservationLocation: ReservationLocation,
  ) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)
    const isPaymentRuleFlowEnabled = flowAPI.experiments.enabled(
      EXPERIMENTS.isPaymentRuleFlowEnabled,
    )

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.reserveReservation(
        flowAPI,
        reservation.id ?? '',
        reservee,
        reservation.revision ?? '',
      )

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation!,
          reservee,
          reservationLocation,
        })

        if (!isPaymentRuleFlowEnabled) {
          return goToReservationConfirmation(flowAPI, finishedReservation.id)
        }

        const checkoutResponse = await checkoutService.createCheckout(
          flowAPI,
          finishedReservation.id,
          reservee,
        )

        if (!checkoutResponse?.checkout) {
          throw new Error('Checkout is undefined')
        }

        const isFreeReservation = isFreeOrder(checkoutResponse.checkout)

        if (isFreeReservation) {
          await checkoutService.createOrderFromCheckout(flowAPI, checkoutResponse.checkout!.id!)

          await goToReservationConfirmation(flowAPI, finishedReservation.id)
        } else {
          await navigateToCheckoutPage(
            flowAPI,
            checkoutResponse.checkout!.id!,
            finishedReservation.id,
          )
        }
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      const apiError: ReserveReservationApiError = {}

      if (isWixHttpError(err)) {
        apiError.applicationCode = err?.response?.data?.details?.applicationError
          ?.code as ReserveReservationApplicationErrorCode
        apiError.message = err?.response?.data?.message
      } else {
        apiError.message = err?.message
      }

      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: apiError,
      })
    }
  }
}
